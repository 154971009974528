import React, { ReactElement, createRef } from "react";
import { useCountUp } from "react-countup";

import { useBreakpoint } from "hooks";
import useIsInEditMode from "hooks/useIsInEditMode";
import {
    addEditAttributes,
    addEditAttributesBlockLevel,
} from "utils/episerver";

import { Icon, Container, Number, Text } from "./KeyFigure.styled";
import KeyFigureProps from "./KeyFigureProps";

const KeyFigure = ({
    id,
    className,
    contentId,
    keyFigure,
    text,
    start = 0,
    delay = 0,
    icon = "office",
    suffix,
    disableThousandsSeparator = false,
}: KeyFigureProps): ReactElement => {
    const isInEditMode = useIsInEditMode();
    const ref = createRef<HTMLHeadingElement>();

    switch (useBreakpoint()) {
        case "XL":
        case "L":
        case "M":
            icon = `${icon}70`;
            break;
        default:
            icon = `${icon}56`;
    }
    const isDecimal = keyFigure && keyFigure % 1 != 0;

    useCountUp({
        ref: ref,
        duration: 1,
        start: start,
        decimals: isDecimal ? 2 : 0,
        end: keyFigure || 0,
        enableScrollSpy: true,
        scrollSpyDelay: delay,
        scrollSpyOnce: true,
        delay: delay,
        separator: disableThousandsSeparator ? "" : " ",
        suffix: suffix || "",
    });

    return (
        <Container
            id={id}
            {...addEditAttributesBlockLevel(contentId)}
            className={className}
        >
            <Icon key={`Icon-${icon}`} icon={icon} />
            {(isInEditMode || keyFigure) && (
                <Number
                    {...addEditAttributes("KeyFigure")}
                    ref={!isInEditMode ? ref : undefined}
                >
                    {!isInEditMode
                        ? start
                        : `${keyFigure || ""}${suffix || ""}`}
                </Number>
            )}
            {(isInEditMode || text) && (
                <Text {...addEditAttributes("Text")}> {text || ""}</Text>
            )}
        </Container>
    );
};

export default React.memo(KeyFigure);
